export default {
	computed: {
		noProductMsg() {
			if (this.isJns()) {
				return {
					message: this.translate('welcome_msg_no_products', { company: 'Jeunesse' }),
					logo: 'jns_symbol_white.svg',
					class: 'my-1',
					style: 'filter: invert(27%) sepia(91%) saturate(5422%) hue-rotate(197deg) brightness(93%) contrast(95%);',
				};
			}
			return {
				message: this.translate('welcome_msg_no_products', { company: 'Velovita' }),
				logo: 'symbol_white.svg',
				class: 'my-4',
				style: 'filter: invert(63%) sepia(96%) saturate(759%) hue-rotate(338deg) brightness(91%) contrast(101%);',
			};
		},
	},
};
